import { h, render, Component } from 'preact';
import OneJourneySearch from './tpl/OneJourneySearch.js'

function openSearchOverlay() {
    $('body').addClass('onejourney-search-overlay-enabled')
            .trigger('revealOffcanvas')
}

$('[data-module="onejourney-search-form"]').each(function () {
    let options = $(this).data('module-options')
    let parent = $(this).closest('.c-onejourney-search')[0]

    window.breakpoints = {
        'small': getComputedStyle(parent).getPropertyValue('--breakpoint--small'),
        'medium': getComputedStyle(parent).getPropertyValue('--breakpoint--medium'),
        'large': getComputedStyle(parent).getPropertyValue('--breakpoint--large')
    }

    if(options?.searchTypes.length) {
        render(<OneJourneySearch { ...options } />, $(this)[0]);
    }
});

$('.c-onejourney-search-overlay').each(function () {
    let options = $(this).data('module-options')

    $(options.trigger).off('click').on('click', function() {
        openSearchOverlay()

        window.dispatchEvent(
            new CustomEvent('onejourneyUpdates', { detail: {
                roomID: false,
                stage: 'initial'
            }})
        )
    })

    $('.js-onejourney-search-overlay-close').on('click', () => {
        $('body').removeClass('onejourney-search-overlay-enabled')
        $('.js-onejourney-overlay-forms').removeClass('is-active')
        $('.js-onejourney-overlay-menu').addClass('is-active')
    })
});

$(window).on('OJ.getRoomsDetails', function(e, results) {
    if(results.price_per_night) {
        $(`[data-dynamic-price=${results.id}]`).text(results.price_per_night.formatted)
    }
})

$('[data-module="onejourney-overlay-button"]').on('click', function(){
    let options = $(this).data('module-options') || {}

    openSearchOverlay()

    window.dispatchEvent(
        new CustomEvent('onejourneyUpdates', { detail: options })
    )

    $('.js-onejourney-search-overlay-close').off('click.removeData')
    $('.js-onejourney-search-overlay-close').on('click.removeData', () => {
        window.dispatchEvent(
            new CustomEvent('onejourneyUpdates', { detail: {
                roomID: false,
                rateCode: false,
                promoRateCode: false,
                searchType: null
            } })
        )
    })
})

$('.js-onejourney-overlay-form-toggle').on('click', function (e) {
    e.preventDefault();
    var id = $(this).find('button').data('id');

    $('.js-onejourney-overlay-form, .js-onejourney-overlay-form-toggle button').each(function () {

        $(this).removeClass('is-active')

        if($(this).data('form-id') == id || $(this).data('id') == id) {
            $(this).addClass('is-active')
        }
    });

    $('.js-onejourney-overlay-forms').addClass('is-active')
    $('.js-onejourney-overlay-menu').removeClass('is-active')

    window.dispatchEvent(
        new CustomEvent('onejourneyUpdates', { detail: {
            stage: 'initial'
        }})
    )
});

$('.js-onejourney-search-overlay-back').on('click', function() {
    $('.js-onejourney-overlay-forms').removeClass('is-active')
    $('.js-onejourney-overlay-menu').addClass('is-active')
})


$(document).on('click.toggle-hotel-select', (event) => {
    let options = $('.js-onejourney-hotel-select-options')
    options.toggleClass('is-active', $(event.target).closest('.js-onejourney-hotel-select').length != 0)
})